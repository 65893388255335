var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',_vm._l((_vm.statisticFields),function(field){return _c('CCol',{key:field.key,staticClass:"mb-3",attrs:{"col":"12","md":"6","lg":"6","xl":"4"}},[_c('TWidgetSimple',{staticClass:"h-100",attrs:{"title":field.label,"clickable":"","color":field.color,"alignment":"left","number":_vm.statistics[field.key] && _vm.statistics[field.key].skus_quantity,"selected":_vm.selected == field.key,"loading":_vm.statisticsLoading},on:{"click":function($event){_vm.selected = field.key}},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t(field.tooltip),
              placement: 'left',
            }),expression:"{\n              content: $t(field.tooltip),\n              placement: 'left',\n            }"}],staticClass:"ml-auto mr-2",attrs:{"name":"cis-info-circle"}})]},proxy:true},{key:"bottom-content",fn:function(){return [(_vm.statistics[field.key])?_c('TTableAsForm',{attrs:{"data":_vm.statistics[field.key],"fields":_vm.fields,"splitLeft":6,"addRowClasses":[]},scopedSlots:_vm._u([{key:"boxes_quantity",fn:function(ref){
            var value = ref.value;
return [_c('TMessageNumber',{staticClass:"h5 m-0",attrs:{"value":value}})]}},{key:"weight",fn:function(ref){
            var value = ref.value;
return [_c('TMessageNumber',{staticClass:"h5 m-0",attrs:{"value":value,"suffix":"kg"}})]}},{key:"volume",fn:function(ref){
            var value = ref.value;
return [_c('TMessageVolume',{staticClass:"h5 m-0",attrs:{"value":value}})]}}],null,true)}):_vm._e()]},proxy:true}],null,true)})],1)}),1),_c('CProgress',{staticClass:"mb-5",staticStyle:{"height":"3px"},attrs:{"max":100,"color":"dark","value":100}}),_c('FilterGoodsStatistic',{attrs:{"store":_vm.store,"loading":_vm.statisticsLoading || _vm.loading},on:{"update:filter":function($event){_vm.filter = $event}}}),_c('CRow',{staticClass:"d-flex align-items-stretch position-relative my-4"},[_vm._l((_vm.boxes),function(box){return _c('SCardSku',{key:box.id,staticClass:"my-3 mx-3",attrs:{"box":box,"enterable":"","showItem":true,"noItems":false,"showWeight":false,"showSfa":false,"showOwner":""}})}),(_vm.loading)?_c('CElementCover',{staticClass:"my-4",attrs:{"opacity":0.4,"center":""}},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{staticClass:"mr-2 h3",attrs:{"content":"Loading"}}),_c('CSpinner',{attrs:{"size":"2xl","color":"success"}})],1)]):_vm._e()],2),_c('CCol',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginate.last > 1),expression:"paginate.last > 1"}],staticClass:"mt-2",attrs:{"col":"12"}},[_c('TPagination',{attrs:{"store":("warehouse." + _vm.store)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }